import "./style.css";
import ReactDOM from "react-dom/client";
import { Canvas } from "@react-three/fiber";
import Experience from "./Experience.jsx";
import { KeyboardControls } from "@react-three/drei";
import Interface from "./Interface.jsx";
import { useState, useEffect } from "react";

const root = ReactDOM.createRoot(document.querySelector("#root"));

const FrontPage = ({ onStartGame, username, setUsername }) => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [isLeaderboardOpen, setIsLeaderboardOpen] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const response = await fetch("http://localhost:3000/api/leaderboard");
        const data = await response.json();

        const formattedData = data.map((entry, index) => ({
          name: entry.username || entry.address,
          score: Math.floor(1000 - index * 3.5),
          rank: ["🏆", "🥈", "🥉"][index] || `#${index + 1}`,
          streak: Math.floor(Math.random() * 10),
          bestTime: entry.time.toFixed(2),
          date: new Date(entry.date).toLocaleDateString(),
        }));

        setLeaderboard(formattedData);
      } catch (error) {
        console.error("Error fetching leaderboard:", error);
        // Fallback to dummy data if API call fails
        // setLeaderboard(
        //   Array.from({ length: 200 }, (_, i) => ({
        //     name: `Anonymous Player ${i + 1}`,
        //     // score: Math.floor(1000 - i * 3.5),
        //     rank: ["🏆", "🥈", "🥉"][i] || `#${i + 1}`,
        //     // streak: Math.floor(Math.random() * 10),
        //     bestTime: (20 + Math.random() * 30).toFixed(2),
        //     date: new Date().toLocaleDateString(),
        //   }))
        // );
      }
    };

    fetchLeaderboard();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleStartGame = () => {
    if (!username.trim()) {
      setErrorMessage("Please enter a username before starting!");
      return;
    }
    setErrorMessage("");
    localStorage.setItem("username", username);
    onStartGame();
  };

  return (
    <div className="frontPage">
      <div
        className="hero"
        style={{
          backgroundImage: "url('/marblerace.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          minHeight: "100vh",
          position: "relative",
          filter: "brightness(2.3)",
          display: "flex",
        }}
      >
        <div
          className="content"
          style={{
            display: "flex",
            width: "100%",
            flexDirection: windowWidth <= 768 ? "column" : "row",
          }}
        >
          <div
            className="hero-overlay"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          ></div>

          <div
            className="leaderboard-container"
            style={{
              position: "relative",
              transform:
                windowWidth <= 768
                  ? `translateY(${isLeaderboardOpen ? "0" : "-290px"})`
                  : `translateX(${isLeaderboardOpen ? "0" : "-290px"})`,
              transition: "transform 0.3s ease-in-out",
              display: "flex",
            }}
          >
            <div
              className="leaderboard"
              style={{
                width: windowWidth <= 768 ? "100vw" : "300px",
                height: windowWidth <= 768 ? "300px" : "100vh",
                overflowY: "auto",
                position: "relative",
                zIndex: 1,
                padding: "2rem",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                msOverflowStyle: "none",
                scrollbarWidth: "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <h2
                className="leaderboard-title"
                style={{
                  color: "#FFD700",
                  textAlign: "center",
                  marginBottom: "1.5rem",
                  textShadow: "0 0 10px rgba(255, 215, 0, 0.5)",
                }}
              >
                Leaderboard
              </h2>
              <div className="leaderboard-entries">
                {leaderboard.map((entry, index) => (
                  <div
                    key={index}
                    className="leaderboard-entry"
                    style={{
                      background:
                        index < 3 ? "rgba(255, 255, 255, 0.1)" : "transparent",
                      padding: "0.8rem",
                      marginBottom: "0.5rem",
                      borderRadius: "8px",
                      display: "grid",
                      gridTemplateColumns: "auto 1fr auto",
                      gap: "1rem",
                      alignItems: "center",
                      transition: "transform 0.2s",
                      cursor: "pointer",
                      "&:hover": {
                        transform: "scale(1.02)",
                      },
                    }}
                  >
                    <div
                      className="rank"
                      style={{
                        fontSize: index < 3 ? "1.2rem" : "1rem",
                        fontWeight: "bold",
                        color: index < 3 ? "#FFD700" : "#fff",
                      }}
                    >
                      {entry.rank}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="name" style={{ color: "#fff" }}>
                        {entry.name}
                      </div>
                      <div style={{ fontSize: "0.8rem", color: "#888" }}>
                        Date: {entry.date}
                      </div>
                    </div>
                    <div
                      className="score"
                      style={{
                        color: "#4CAF50",
                        fontWeight: "bold",
                      }}
                    >
                      {entry.bestTime}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <button
              onClick={() => setIsLeaderboardOpen(!isLeaderboardOpen)}
              style={{
                position: "absolute",
                ...(windowWidth <= 768
                  ? {
                      bottom: "-30px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      borderRadius: "0 0 5px 5px",
                    }
                  : {
                      right: "-30px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      borderRadius: "0 5px 5px 0",
                    }),
                background: "rgba(0, 0, 0, 0.7)",
                border: "none",
                color: "white",
                padding: windowWidth <= 768 ? "0.5rem 1rem" : "1rem 0.5rem",
                cursor: "pointer",
                zIndex: 2,
              }}
            >
              {windowWidth <= 768
                ? isLeaderboardOpen
                  ? "↑"
                  : "↓"
                : isLeaderboardOpen
                ? "←"
                : "→"}
            </button>
          </div>

          <div
            className="hero-content"
            style={{
              position: "relative",
              zIndex: 1,
              padding: windowWidth <= 768 ? "1rem" : "2rem",
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: windowWidth <= 768 ? "60vh" : "auto",
            }}
          >
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter your username"
              style={{
                padding: "1rem",
                fontSize: "1.2rem",
                borderRadius: "0.5rem",
                border: "2px solid #4CAF50",
                marginBottom: "2rem",
                background: "rgba(0, 0, 0, 0.7)",
                color: "white",
                textAlign: "center",
                width: "80%",
                maxWidth: "300px",
              }}
            />
            {errorMessage && (
              <div
                style={{
                  color: "#ff4444",
                  marginBottom: "1rem",
                  textAlign: "center",
                  fontSize: "1.1rem",
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  padding: "0.5rem 1rem",
                  borderRadius: "0.5rem",
                }}
              >
                {errorMessage}
              </div>
            )}
            <button
              onClick={handleStartGame}
              className="start-button"
              style={{
                marginTop: windowWidth <= 768 ? "1rem" : "3rem",
                padding: windowWidth <= 768 ? "1.5rem 3rem" : "2rem 4rem",
                fontSize: windowWidth <= 768 ? "2rem" : "2.5rem",
                fontWeight: "bold",
                background:
                  "linear-gradient(135deg, #4CAF50, #9C27B0, #2196F3)",
                border: "none",
                borderRadius: "1.5rem",
                cursor: "pointer",
                transform: "scale(1.2)",
                transition: "transform 0.2s ease",
                boxShadow: "0 0 20px rgba(76, 175, 80, 0.5)",
              }}
              onMouseEnter={(e) => (e.target.style.transform = "scale(1.3)")}
              onMouseLeave={(e) => (e.target.style.transform = "scale(1.2)")}
            >
              <span className="button-text" style={{ color: "white" }}>
                Play Now
              </span>
              <span className="button-shine"></span>
            </button>

            <div
              style={{
                display: "flex",
                gap: "20px",
                marginTop: "2rem",
                position: "absolute",
                bottom: "20px",
              }}
            >
              <a
                href="https://twitter.com/your_handle"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="white">
                  <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                </svg>
              </a>
              <a
                href="https://t.me/marbleraceofficial"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="white">
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4.64 6.8c-.15 1.58-.8 5.42-1.13 7.19-.14.75-.42 1-.68 1.03-.58.05-1.02-.38-1.58-.75-.88-.58-1.38-.94-2.23-1.5-.99-.65-.35-1.01.22-1.59.15-.15 2.71-2.48 2.76-2.69.01-.03.01-.14-.07-.2-.08-.06-.19-.04-.27-.02-.12.02-1.96 1.25-5.51 3.67-.52.36-.99.54-1.41.53-.46-.01-1.35-.26-2.01-.48-.81-.27-1.46-.42-1.4-.88.03-.24.37-.49 1.02-.75 4.01-1.74 6.69-2.89 8.04-3.45 3.82-1.59 4.61-1.87 5.12-1.88.12 0 .37.03.54.18.14.12.18.28.2.45-.02.07-.02.13-.03.19z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Game = ({ username }) => (
  <KeyboardControls
    map={[
      { name: "forward", keys: ["ArrowUp", "KeyW"] },
      { name: "backward", keys: ["ArrowDown", "KeyS"] },
      { name: "leftward", keys: ["ArrowLeft", "KeyA"] },
      { name: "rightward", keys: ["ArrowRight", "KeyD"] },
      { name: "jump", keys: ["Space"] },
    ]}
  >
    <Canvas
      shadows
      camera={{
        fov: 45,
        near: 0.1,
        far: 200,
        position: [2.5, 4, 6],
      }}
    >
      <Experience />
    </Canvas>
    <Interface username={username} />
  </KeyboardControls>
);

const App = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [username, setUsername] = useState("");
  return isPlaying ? (
    <Game username={username} />
  ) : (
    <FrontPage
      onStartGame={() => setIsPlaying(true)}
      username={username}
      setUsername={setUsername}
    />
  );
};

root.render(<App />);
